import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/assets/images/gameplay/tiles-build-decks.png");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/assets/images/gameplay/tiles-hardcore.png");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/assets/images/gameplay/tiles-play-now.png");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/assets/images/home/arenas.png");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/assets/images/home/blinds.png");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/assets/images/home/boosters.png");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/assets/images/home/build-decks.png");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/assets/images/home/degen-program-bg.png");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/assets/images/home/degen-program.png");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/assets/images/home/hero-section-bg.png");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/assets/images/home/hero-section-mobile-bg.png");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/assets/images/home/intro-section-bg.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/assets/images/home/prize-money.png");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/assets/images/home/token-presale.png");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/assets/images/home/video-section-bg.png");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/systems/home/components/CharactersSection.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/systems/home/components/NewsletterSection.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/systems/home/components/TabsSection.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/systems/shared/components/TabsSection.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/systems/ui/components/Listbox.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/systems/ui/components/Modal.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/systems/ui/components/Stat.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/systems/ui/components/Tooltip.tsx");
